<template>
  <header
    class="property-header"
    :style="{ backgroundImage: `url(${mainImage})` }"
    @click="openGallery"
  >
    <div class="overlay" v-if="isSold">
      <p class="overlay-text">{{ $t("sold") }}</p>
    </div>
    <div class="overlay" v-if="isUnavailable">
      <p class="overlay-text">{{ $t("unavailable") }}</p>
    </div>
    <div class="overlay" v-if="isPreContract">
      <p class="overlay-text">{{ $t("pre_contract") }}</p>
    </div>
    <!-- TODO : est-ce qu'on garde le bouton retour ? -->
    <!-- <router-link :to="{name: 'search'}" class="back-button">{{ $t('back') }}</router-link> -->
    <button class="play-button" v-if="!isSold && !isUnavailable && !isPreContract">
      <Play width="75" height="75" />
    </button>
  </header>
</template>

<script>
import Play from "@/components/Icons/Play.vue";
import { mapActions } from "vuex";

export default {
  name: "PropertyHeader",
  props: {
    mainImage: String,
    isSold: {
      type: Boolean,
      default: false
    },
    isUnavailable: {
      type: Boolean,
      default: false
    },
    isPreContract: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Play
  },
  methods: {
    ...mapActions(["openPropertyGallery"]),
    openGallery(event) {
      if (!event.target.classList.contains("back-button")) {
        this.openPropertyGallery(true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.property-header {
  height: 35vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;

  @media #{$tablet-portrait-and-up} {
    height: 50vh;
  }

  @media #{$desktop-and-up} {
    height: 60vh;
    min-height: 550px;
  }

  button {
    cursor: pointer;
  }
}

// .back-button {
// 	color: $black;
// 	text-decoration: none;
// 	border-radius: 22px;
// 	background-color: rgba(255, 255, 255, .75);
//     font-size: 14px;
// 	font-weight: 600;
// 	padding: $space-1x $space-2x;
// 	display: inline-block;
// 	margin-left: $space-2x;
// 	margin-top: $space-1x;
// }

.play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background-color: transparent;
  @media #{$desktop-and-up} {
    top: calc(50% - #{$space-7x}); // In order to have the button centered
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.overlay-text {
  text-align: center;
  color: $pink;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  position: relative;
  font-size: 35px;

  @media #{$tablet-portrait-and-up} {
    font-size: 55px;
  }

  @media #{$desktop-and-up} {
    top: calc(50% - #{$space-4x});
  }
}
</style>
